
import '../scss/styles.scss'

import 'babel-polyfill'

import 'perfect-scrollbar/dist/perfect-scrollbar.min'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

import 'tiny-slider/dist/tiny-slider.css'

import './src/common'
import './src/tiny-sliders'
