import {tns} from "tiny-slider";

window.addEventListener('load', ()=> {
    typesInsuranceInit();
});

const typesInsuranceInit = ()=> {
    if (!document.getElementById('typesInsuranceCarousel')) {
        return;
    }

    const slider = tns({
        container: '#typesInsuranceCarousel',
        items: 1,
        controls: false,
        gutter: 20,
        navPosition: 'bottom',
        swipeAngle: false,
        responsive: {
            "750": {
                items: 2
            },
            "992": {
                controls: true,
                items: 3
            }
        },
        onInit: (e) => {
            setTimeout(()=> {
                document.querySelectorAll('.js-types-insurance-item').forEach(el => el.classList.add('is-equal-height'))
            }, 300);
        }
    });
}
